import React from "react";
import { Helmet } from "react-helmet";
import { useStaticQuery, graphql } from "gatsby";
import { Messages, Lang, translate } from "./tr";

type Meta = {
  name: string;
  content: string;
};

interface Props {
  description?: string;
  lang: Lang;
  meta?: Meta[];
  keywords?: string[];
  title?: Messages;
  rawTitle?: string;
  image?: string;
}

const defaultKeywords: { [key in Lang]: string[] } = {
  [Lang.cs]: [
    "obrazy",
    "obrazy na zakázku",
    "malování",
    "kreslení",
    "olejové barvy",
    "krajina",
    "obrazy na plátně",
    "obrazy na stěnu",
    "olejomalba",
    "akvarel",
    "plakáty",
    "reprodukce obrazů",
    "fotoplátno",
    "tisk na plátno",
  ],
  [Lang.en]: [
    "paintings",
    "painting on the wall",
    "drawings",
    "oil paints",
    "paintings for sale",
    "painting art gallery",
    "da vinci",
    "prints",
    "illustration",
    "landscapes",
    "wallpapers",
    "custom painting",
    "aquarell",
  ],
};

const defaultDescription: { [key in Lang]: string[] } = {
  [Lang.cs]: [
    "Olejové a akvarelové obrazy podle vašich představ vytvoří ducha vašeho domova, možné reprodukce na výtvarný papír nebo plátno.",
  ],
  [Lang.en]: [
    "Oil paintings, aquarelle pictures according to your imagination. They create a spirit of your home. Prints, reproductions on canvas or paper.",
  ],
};

function SEO({
  description,
  lang,
  meta,
  keywords,
  title,
  rawTitle,
  image,
}: Props) {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            author
            lang
            image
          }
        }
      }
    `
  );

  let titlePrefix = rawTitle;
  if (title !== undefined) {
    titlePrefix = translate(title, lang);
  }

  const metaDescription =
    description || defaultDescription[lang] || site.siteMetadata.description;
  const metaKeywords = keywords || defaultKeywords[lang];
  const metaLang = lang || site.siteMetadata.lang;
  const metaImage = image || site.siteMetadata.image;
  const metaTitle = `${titlePrefix !== undefined ? `${titlePrefix} | ` : ""}${
    site.siteMetadata.title
  }`;
  // const metaTitle = `${title !== undefined ? `${translate(title, lang)} | ` : ''}${site.siteMetadata.title}`
  const metaOgTitle = `${titlePrefix !== undefined ? `${titlePrefix} | ` : ""}${
    site.siteMetadata.title
  }`;

  return (
    <Helmet
      htmlAttributes={{
        lang: metaLang,
      }}
      title={metaTitle}
      titleTemplate={"%s"}
      meta={[
        {
          name: `viewport`,
          content: "width=device-width, initial-scale=1",
        },
        {
          name: `description`,
          content: metaDescription,
        },
        {
          name: `author`,
          content: site.siteMetadata.author,
        },
        {
          property: `og:title`,
          content: metaOgTitle,
        },
        {
          property: `og:description`,
          content: metaDescription,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          property: `og:image`,
          content: metaImage,
        },
        {
          property: `og:image:url`,
          content: metaImage,
        },
        {
          name: `twitter:card`,
          content: `summary`,
        },
        {
          name: `twitter:creator`,
          content: site.siteMetadata.author,
        },
        {
          name: `twitter:title`,
          content: metaTitle,
        },
        {
          name: `twitter:description`,
          content: metaDescription,
        },
        {
          name: `twitter:image`,
          content: metaImage,
        },
        {
          name: `theme-color`,
          content: "#ffffff",
        },
      ]
        .concat(
          metaKeywords.length > 0
            ? {
                name: `keywords`,
                content: metaKeywords.join(`, `),
              }
            : []
        )
        .concat(meta || [])}
    />
  );
}

export default SEO;
