import * as React from 'react';
import Icon from './Icon';

const Instagram: React.FunctionComponent = () => (
  <Icon height={18} width={18} viewBox={'0 0 1000 1000'} color={'#6f471c'}>
    <path d="M759.4,10H240.6C113.8,10,10,113.8,10,240.6v518.8C10,886.2,113.8,990,240.6,990h518.8C886.2,990,990,886.2,990,759.4V240.6C990,113.8,886.2,10,759.4,10z M932.4,759.4c0,95.3-77.6,172.9-172.9,172.9H240.6c-95.3,0-172.9-77.6-172.9-172.9V413.5h184.9c-16.5,35.2-26.3,74-26.3,115.3c0,151,122.8,273.8,273.8,273.8s273.8-122.8,273.8-273.8c0-41.3-9.9-80.1-26.3-115.3h184.9V759.4L932.4,759.4z M716.2,528.8C716.2,648,619.2,745,500,745s-216.2-97-216.2-216.2c0-119.2,97-216.2,216.2-216.2S716.2,409.6,716.2,528.8z M710.5,355.9C660.3,294.8,585.1,255,500,255s-160.3,39.8-210.5,100.9H67.6V240.6c0-95.3,77.6-172.9,172.9-172.9h518.8c95.3,0,172.9,77.6,172.9,172.9v115.3H710.5z M843.2,166.9v74.8c0,17.2-14.1,31.3-31.2,31.3h-78.9c-17.3-0.1-31.4-14.1-31.4-31.3v-74.8c0-17.2,14.1-31.3,31.4-31.3H812C829.2,135.6,843.2,149.7,843.2,166.9z"/>
  </Icon>
)

export default Instagram;
