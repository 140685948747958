import * as React from 'react';
import Facebook from '../../components/Icons/Facebook';
import Deviantart from '../../components/Icons/Deviantart';
import Instagram from '../../components/Icons/Instagram';
import { SocialItem } from './styled/header'

const social = [
  {
    url: 'https://www.instagram.com/paave.art/',
    icon: <Instagram />,
    title: 'Instagram',
    class: 'insta'
  },
  {
    url: 'https://www.facebook.com/paaveart/',
    icon: <Facebook />,
    title: 'Facebook',
    class: 'fb'
  },
  {
    url: 'https://www.deviantart.com/notokfun',
    icon: <Deviantart />,
    title: 'Deviant Art',
    class: 'da'
  },
]

const SocialNav: React.FunctionComponent = () => (
  <>
    {social.map(item => (
      <SocialItem key={item.url}>
        <a className={item.class} href={item.url} target="_blank" title={item.title}>{item.icon}</a>
      </SocialItem>
    ))}
  </>
);

export default SocialNav;
