import * as React from 'react';
import Icon from './Icon';

const DeviantArt: React.FunctionComponent = () => (
  <Icon height={18} width={18} viewBox={'0 0 1000 1000'} color={'#6f471c'}>
    <path d="M801.5,188.5L623.1,531.2l14.1,18.3h164.3v244.4H502.9L477,811.5l-83.6,160.8L375.7,990H198.5V811.5l178.4-343.4l-14.1-17.7H198.5V206.1h298.6l25.9-17.7l83.6-160.8L624.3,10h177.3V188.5L801.5,188.5z"/>
  </Icon>
)

export default DeviantArt;
