import * as React from 'react';
import Icon from './Icon';

const Logo: ({color}: { color: string }) => JSX.Element = ({ color }: { color: string }) => (
  <Icon height={60} width={70} viewBox={'0 0 26.458333 26.458333'} style={{ marginRight: 5}}>
    <g
      fillOpacity="1"
      stroke="#b5672f"
      strokeDasharray="none"
      strokeMiterlimit="4"
      strokeOpacity="1"
      strokeWidth="0.996"
      transform="matrix(1.00747 0 0 1 -.046 -.066)"
    >
      <path
        fill="#b5672f"
        strokeLinecap="butt"
        strokeLinejoin="miter"
        strokeWidth="0"
        d="M6.2 16.522s-2.927 1.775-3.895 2.575c-.968.8-2.26 2.042-2.26 2.042s2.291-.643 3.35-1.12c1.058-.475 3.141-2.132 3.141-2.132z"
      ></path>
      <path
        id="heart"
        fill={color}
        strokeLinecap="butt"
        strokeLinejoin="miter"
        strokeWidth="0.264"
        d="M9.998 7.458c2.787-1.42.538-6.586-2.104-3.954-1.05 1.047-2.728 3.88-1.91 10.048.457 3.434.08.25.515 3.697.434 3.448-.5 7.185-2.598 8.821-1.587 1.237 1.197-3.973 2.723-5.289 7.239-6.244 10.156-7.145 12.199-11.954 2.043-4.808.15-8.357-1.498-8.603-1.648-.247-3.034 1.355-4.405 2.842-1.37 1.487-3.193 4.53-2.922 4.392z"
      ></path>
      <ellipse
        cx="17.35"
        cy="8.237"
        fill="none"
        strokeWidth="0.265"
        rx="0.941"
        ry="1.632"
        transform="matrix(.9748 .2231 -.35873 .93344 0 0)"
      ></ellipse>
      <ellipse
        cx="17.883"
        cy="2.709"
        fill="none"
        strokeWidth="0.265"
        rx="0.941"
        ry="1.632"
        transform="matrix(.9748 .2231 -.35873 .93344 0 0)"
      ></ellipse>
      <ellipse
        cx="14.899"
        cy="12.013"
        fill="none"
        strokeWidth="0.265"
        rx="0.941"
        ry="1.632"
        transform="matrix(.9748 .2231 -.35873 .93344 0 0)"
      ></ellipse>
      <path
        fill="#b5672f"
        strokeLinecap="butt"
        strokeLinejoin="miter"
        strokeWidth="0"
        d="M13.053 12.287s4.022-3.697 5.3-4.533c1.277-.836 1.982-1.05 1.982-1.05s.588.851.758 1.017c.11.051-1.416 1.45-1.687 1.657-1.439 1.044-5.407 4.537-6.323 3.649-.128-.19-.03-.74-.03-.74z"
      ></path>
      <g
        fill="#b5672f"
        strokeWidth="0.236"
        transform="matrix(-.19196 -.51404 .48422 -.33214 -213.104 -41.453)"
      >
        <path
          fill="#b5672f"
          fillOpacity="1"
          stroke="#b5672f"
          strokeDasharray="none"
          strokeMiterlimit="4"
          strokeOpacity="1"
          strokeWidth="0"
          d="M-323.024 366.372l1.057-2.024c1.134-2.173 1.256-2.452 1.426-3.265.255-1.213.08-2.419-.465-3.225-.39-.426-1-1.385-1.265-1.537-.9.64-2.125.696-2.862.756-1.368 3.646-.678 5.732.122 6.694 1.873 2.26 1.644 1.578 1.873 2.26zm.211-2.41c-.007.009-.013.011-.015.009-.908-.285-1.843-2.601-.916-2.559.3.014.575.702.852.803.174-.334-.01-.916.522-1 .533-.086.725.112.577.565-.265.808-.395 1.421-1.02 2.182z"
        ></path>
      </g>
    </g>
  </Icon>
)

export default Logo;
