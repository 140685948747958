import styled from 'styled-components'
import { rem } from 'polished'
import { media } from '../../../components/styled/themeVariables'

export const Hamburger = styled.div`
  width: 30px;
  padding: ${rem(14)} ${rem(15)};
  cursor: pointer;
  margin-top: ${rem(0)};
  position: absolute;
  top: 14px;
  right: 0;

  ${media.tablet`
     display: none;
  `}
}
`

export const Line = styled.div`
  width: 100%;
  height: 1px;
  margin-bottom: 5px;
  background-color: ${(props) => props.theme.colors.brandOppositeDark};
}
`
