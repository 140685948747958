import * as React from 'react';
import Icon from './Icon';

const Facebook: React.FunctionComponent = () => (
  <Icon height={18} width={18} viewBox={'0 0 1000 1000'} color={'#6f471c'}>
    <g transform="matrix(1 0 0 -1 0 1536)"><path d="M754.4,1518.9v-155.5H662c-33.8,0-56.5-7.1-68.3-21.2c-11.8-14.1-17.7-35.3-17.7-63.6v-111.3h172.6l-23-174.3H576V546H395.8v447H245.6v174.3h150.2v128.4c0,73,20.4,129.7,61.3,169.9c40.8,40.2,95.2,60.4,163.1,60.4C677.9,1526,722.6,1523.6,754.4,1518.9z"/></g>
  </Icon>
)

export default Facebook;
