import React from 'react'
import styled from 'styled-components'

const SVG = styled.svg`
  vertical-align: middle;
`

export interface IconStyle {
  border?: string;
  borderRadius?: string;
  enableBackground?: string;
  marginRight?: number;
};

interface Props {
  viewBox: string;
  width: number;
  height: number;
  color?: string;
  style?: IconStyle;
  children?: JSX.Element;
}

const Icon = ({
  viewBox,
  children,
  width,
  height,
  color = 'white',
  style,
  ...props
}: Props) => (
  <SVG {...props} viewBox={viewBox} width={width} height={height} fill={color} style={style}>
    {children}
  </SVG>
)

export default Icon
