import styled from 'styled-components'
import { rem, lighten } from 'polished'
import { Flex } from 'reflexbox'
import { media } from '../../../components/styled/themeVariables'
import Logo from "../../Icons/Logo";

export const Header = styled.header`
  position: fixed;
  z-index: 100;
  width: 100%;
  height: 70px;
  background-color: ${(props) => props.theme.colors.bg};
  padding-bottom: ${rem(10)};

  ${media.tablet`
    position: static;
    max-height: 250px;
    height: auto;
    padding-bottom: ${rem(30)};
  `}

  a {
    text-decoration: none;
  }
}
`

export const PageLogo = styled.div`
  justify-content: left;
  display: flex;
  align-items: center;
  padding: ${rem(10)} 0 ${rem(10)} ${rem(10)};

  svg {
    width: 70px;
    height: 70px;
  }

  ${media.tablet`
    padding: ${rem(10)} 0 0 ${rem(10)};
      svg {
        width: 100px;
        height: 100px;
      }
  `}

  ${media.desktop`
    margin: 20px 0 0;
  `}
`;

export const PageTitle = styled.h1`
  font-size: ${rem(32)};
  padding: 0 ${rem(10)} 0;
  margin: 0;
  color: ${(props) => props.theme.colors.menu};
  font-weight: 400;
  line-height: 1;
  border-bottom-left-radius: 2px;
  border-bottom-right-radius: 2px;
  text-shadow: 1px 0 0 ${(props) => props.theme.colors.menu};
  font-family: ${(props) => props.theme.fontFamily.Tangerine};

  ${media.tablet`
    font-size: ${rem(48)};
    padding: 26px 10px 0;
  `}

   ${media.desktop`
      padding: 28px 0 0;
      font-size: ${rem(52)};
   `}
`
export const Slogan = styled.strong`
  font-weight: normal;
  color: ${(props) => props.theme.colors.menu};
  text-decoration: none;
  display: block;
  font-size: ${rem(13)};
  font-style: italic;
  letter-spacing: 1px;
  padding: 7px 10px 7px;

  ${media.tablet`
    font-size: ${rem(17)};
    margin-bottom: ${rem(20)};
    text-align: center;
  `}

  ${media.desktop`
    padding: 12px 0 0 0;
  `}
`;

export const NavFlex = styled(Flex)`
  width: 100%;
  flex-direction: column;
  background-color: ${(props) => props.theme.colors.bg};

  ${media.tablet`
    flex-direction: row;
    margin-top: ${rem(10)};
  `}
`

export const Nav = styled.nav`
  width: 100%;
  text-align: center;
  z-index: 200;
  border-bottom: 1px solid ${(props) => props.theme.colors.border};
  box-shadow: 2px 1px ${(props) => props.theme.colors.border};
  background-color: ${(props) => props.theme.colors.bg};

   ${media.tablet`
      display: flex;
      align-self: flex-end;
      background-color: transparent;
      width: auto;
      position: static;
      box-shadow: none;
      border: 0;
   `}
`

export const List: any = styled.ul`
  flex-direction: column;
  box-sizing: border-box;
  width: 100%;
  margin: 0;
  padding: ${rem(16)} ${rem(18)} ${rem(16)} ${rem(18)};
  position: relative;
  z-index: 2;
  list-style: none;
  font-family: ${(props) => props.theme.fontFamily.Raleway};
  background-color: ${(props) => props.theme.colors.bg} ;
  display: ${(props: any) => props.open ? 'flex': 'none'};

  ${media.tablet`
    position: static;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: ${rem(16)} ${rem(0)};
    background: transparent;
    width: auto;
    text-align: center;
  `}

  ${media.desktop`
    padding: ${rem(16)} ${rem(0)} ${rem(8)};
  `}
`

export const ListItem: any = styled.li`
  font-size: ${rem(22)};
  padding: ${rem(15)};
  border-top: 1px solid ${(props) => props.theme.colors.border};
  font-family: ${(props) => props.theme.fontFamily.Manrope};
  white-space: nowrap;

  a {
    text-decoration: none;
    color: ${(props) => props.theme.colors.menu};

    :hover {
      color: ${(props) => props.theme.colors.linkDark};
    }
  }

  .active {
    color: ${(props) => props.theme.colors.linkDark};

    &:hover {
      color: ${(props) => props.theme.colors.linkDark};
    }
  }

  ${media.tablet`
    font-size: ${rem(17)};
    display: inline-block;
    padding: 3px 20px 3px 20px;
    border-top: none;
    min-height: 40px;

    + li {
      border-left: 1px solid ${(props) => props.theme.colors.borderMenu};
    }
  `}

  ${media.desktop`
    font-size: ${rem(19)};
    padding: 3px 30px 3px 0;
    + li {
      padding-left: 30px;
    }
  `}
`

export const ListItemDesc = styled.span`
  display: block;
  font-size: ${rem(14)};
`

export const SocialItem = styled.li`
  display: inline-block;
  text-align: center;

  a {
    display: flex;
    align-items: center;
    justify-content: center;
    text-decoration: none;
    border: 1px solid ${(props) => props.theme.colors.bg};
    width: 30px;
    height: 30px;
    line-height: 30px;
    margin-right: 8px;
    border-radius: 3px;

    ${media.desktop`
        width: 30px;
        height: 30px;
        line-height: 30px;
    `}

    &:hover {
      border-color: ${(props) => props.theme.colors.brand};
    }

    svg {
        fill: ${(props) => props.theme.colors.brand};

        &:hover {
            fill: ${(props) => lighten(0.3, props.theme.colors.brand)};
        }
    }
  }
`

export const SocialTab = styled.ul`
  display: block;
  padding-left: 0;
  padding-right: 10px;
  position: relative;
  z-index: 30;

  ${props => !props.open ? `display: none;` : `display: block;`}

  ${media.tablet`
     display: block;
     position: absolute;
     top: 0;
     right: 0;
     z-index: 3;
  `}

  ${media.desktop`
     display: block;
     margin: ${rem(20)} 0 40px;
  `}
`

export const StyledLogo = styled(Logo)`
  svg, path {
      fill: ${(props) => props.theme.colors.logo};
  }
`

export const LogoText = styled.div`
  text-align: center;
`
