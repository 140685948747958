import { createGlobalStyle } from 'styled-components';
import { rem, normalize } from 'polished'
import { media } from './themeVariables'
import { withPrefix } from 'gatsby'

const trees = withPrefix('/trees.png');
const prince = withPrefix('/thelittleprince.png');

/* eslint-disable no-unused-expressions */
export const Global = createGlobalStyle`
  ${normalize()}

  html {
    background-color: ${(props) => props.theme.colors.bg};
    background-image: url(${trees});
    background-position: 0% 100%;
    background-attachment: fixed;
    background-repeat: no-repeat;
  }

  body {
    margin: 0;
    font-family: ${(props) => props.theme.fontFamily.base};
    font-size: 16px;
    background-color: ${(props) => props.theme.colors.bg};
    background-position: 100% 42%;
    background-attachment: fixed;
    background-repeat: no-repeat;
    color: ${(props) => props.theme.colors.text};
    letter-spacing: 0.5px;
    font-family: ${(props) => props.theme.fontFamily.Manrope};
  }

  h1, h2, h3, h4, h5, h6 {
    font-family: ${(props) => props.theme.fontFamily.base};
    font-weight: 500;
    font-family: ${(props) => props.theme.fontFamily.Manrope};
    color: ${(props) => props.theme.colors.link}
  }

  h3 {
    font-size: ${rem(24)}
  }

  p, li, article {
    font-size: ${rem(16)};
    line-height: 2;
    font-weight: 400;

    ${media.tablet`
      font-size: ${rem(18)};
    `}
  }

  strong {
    font-weight: 600;
  }

  a {
    color: ${(props) => props.theme.colors.link};
  }

  .prince {
    background-image: url(${prince});
    background-position: 100% 100%;
    background-attachment: fixed;
    background-repeat: no-repeat;
    background-size: 10%;
  }

  .pull-right {
    float: right;
  }

  .disable-scroll {
    overflow-y: hidden;
  }

  cite {
    padding: 0 0 30px;
    color: ${(props) => props.theme.colors.cite};
    font-size: 17px;
    line-height: 1.4;

    p {
        line-height: 1.4;
    }
  }

  img[src*='#center'] {
    display: block;
    margin: auto;
    max-width: 100%;
  }
`;
