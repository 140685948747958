import styled from 'styled-components'
import { Box } from 'reflexbox'
import { media } from '../styled/themeVariables'

export const Container = styled(Box)`
   ${props => !props.noPadding
    ? 'padding: 0 15px;'
    : ''
  }

  ${media.tablet`
    max-width: ${(props) => props.theme.breakpoints.tablet}px;
    padding: 0;
  `}
  ${media.desktop`
    max-width: ${(props) => props.theme.breakpoints.desktop}px
  `}
`

Container.defaultProps = {
  mx: 'auto'
}

export const GalleryContainer = styled.div`
  margin: 0;

  ${media.tablet`
    margin: 0 50px;
  `}
`
