/* eslint-disable jsx-a11y/anchor-is-valid */
import * as React from 'react';
import {useEffect, useState} from 'react';
import { Box } from 'reflexbox';
import { Link } from 'gatsby'
import {List, ListItem, ListItemDesc, Nav as NavStyle, SocialItem, SocialTab} from './styled/header';
import { Hamburger, Line } from './styled/hamburger';
import SocialNav from "./SocialNav";
import { Messages, Translate } from '../tr';
import { LangLink } from '../lang-link';

interface Nav {
  url: string,
  name: Messages,
  desc?: string,
  external?: boolean,
}

const nav: Nav[] = [
  {
    url: 'https://www.inprnt.com/gallery/paaveart/',
    name: 'E-shop',
    external: true,
  },
  {
    url: '/oil-gallery',
    name: 'Oil',
  },
  {
    url: '/watercolor-gallery',
    name: 'Watercolor',
  },
  {
    url: '/dead-art',
    name: 'Dark Oil',
  },
  {
    url: '/blog',
    name: 'Blog',
  },
  {
    url: '/contact',
    name: 'Contact',
  }
]

const storageLang = 'lang';

interface Props {
  lang: string;
  location: {
    pathname: string;
  }
}

const Navigation = ({lang = 'cs', location}: Props) => {
  const [isOpen, toggle] = useState(false);
  const toggleMenu = () => toggle(!isOpen);
  const isCzech = lang === 'cs';

  useEffect(() => {
    if (!localStorage.getItem(storageLang)) {
      localStorage.setItem(storageLang, 'cs');
    }
  });

  return (
  <NavStyle>
    <Hamburger onClick={toggleMenu}>
      <Line />
      <Line />
      <Line />
    </Hamburger>

    <Box order={[1, 1 , 2]}>
      <List open={isOpen}>
        {nav.map((item) => {
          return (
            item.external ? (
              <ListItem key={item.url} active={item.url === ''}>
                <a href={item.url} target='_blank'>
                  <Translate text={item.name}/>
                  <ListItemDesc>{item.desc}</ListItemDesc>
                </a>
              </ListItem>
            ) : (
              <ListItem key={item.url} active={item.url === ''}>
                <LangLink to={item.url} activeClassName="active">
                  <Translate text={item.name}/>
                  <ListItemDesc>{item.desc}</ListItemDesc>
                </LangLink>
              </ListItem>
            )
          );
        })}
      </List>
      <SocialTab width={[ 1, 0, 0 ]} my={[5, 0, 0]} open={isOpen}>
       <SocialItem>
         <Link
           to={isCzech
             ? `/en${location.pathname}`
             : `${location.pathname.substr(lang.length + 1)}`}
           onClick={() => localStorage.setItem(storageLang, isCzech ? 'en' : 'cs')}
           style={{ position: "relative", top: -2 }}
         >
           {isCzech
             ? 'EN'
             : 'CZ'
           }
         </Link>
       </SocialItem>
        <SocialNav />
      </SocialTab>
    </Box>
  </NavStyle>
)}

export default Navigation;

