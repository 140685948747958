import React from "react";
import { Helmet } from "react-helmet";
import { StaticQuery, graphql } from "gatsby";
import { Box } from "reflexbox";
import Footer from "./layout/footer";
import Header from "./layout/header";
import { Global } from "./styled/global";
import { Lang } from "./tr";
import { ThemeProvider } from "styled-components";
import theme from "./styled/themeVariables";
import {FlexLayout} from "../styled/layout";

export const LayoutContext = React.createContext({
  lang: "cs",
});

interface Props {
  children: React.ReactNode;
  scrollDisable?: boolean;
  lang: Lang;
  location: {
    pathname: string;
  };
  customTheme?: Record<string, unknown>;
}

const Layout = ({ children, scrollDisable = false, lang, location, customTheme }: Props) => {
  const useTheme = customTheme !== undefined ? customTheme : theme;
  return (
    <StaticQuery
      query={graphql`
      query SiteTitleQuery {
        site {
          siteMetadata {
            title
          }
        }
      }
    `}
      render={() => (
        <LayoutContext.Provider
          value={{
            lang,
          }}
        >
          <ThemeProvider theme={useTheme}>
            <Helmet>
              <meta name="facebook-domain-verification" content="95qvuwft7yp3xq07ppapdcxwg7fby9" />
              <link
                href="https://fonts.googleapis.com/css2?family=Manrope:wght@300;400;500;600&display=swap"
                rel="stylesheet"
              />
              <link
                href="https://fonts.googleapis.com/css2?family=Tangerine:wght@400;700&display=swap"
                rel="stylesheet"
              />
            </Helmet>
            <Global />
            <Header lang={lang} location={location} />
            <FlexLayout
              alignItems={"stretch"}
              pt={[5, 5, 6, 1, 1]}
              pb={1}
              style={{ overflowY: `${scrollDisable ? "hidden" : "visible"}` }}
            >
              <Box width={[1]}>{children}</Box>
            </FlexLayout>
            <Footer customTheme={useTheme} />
          </ThemeProvider>
        </LayoutContext.Provider>
      )}
    />
  )
};

export default Layout;
