import * as React from 'react';
import { Flex, Box } from 'reflexbox';
import { FooterBox } from './styled/footer'
import SocialNav from "./SocialNav";
import {Container} from "./container";

interface Props {
  customTheme?: Record<string, unknown>;
}

const Footer: React.FunctionComponent<Props> = ({ customTheme }) => (
  <FooterBox customTheme={Boolean(customTheme)}>
    <Container>
      <Flex flexWrap={'wrap'} justifyContent={'space-between'} alignItems={'center'}>
        <Box width={[1,1, 1/2]}>
          <p style={{ color: '#b88727' }}>Paave Art {(new Date().getFullYear())} &copy; design &amp; web Pavlína Ostrá</p>
        </Box>
        <Box width={[1,1, 1/2]}>
          <SocialNav />
        </Box>
      </Flex>
    </Container>
  </FooterBox>
)

export default Footer;
