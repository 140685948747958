import * as React from 'react';
import { Container } from './container';
import {Header, PageTitle, PageLogo, Slogan, NavFlex, StyledLogo, LogoText} from './styled/header';
import Nav from './nav';
import { Translate } from '../tr';
import { LangLink } from '../lang-link';

interface Props {
  lang: string;
  location: {
    pathname: string;
  }
}

const Top = ({ lang, location }: Props) =>
  (
    <Header>
      <Container noPadding>
        <NavFlex justifyContent={'space-between'}>
          <LangLink to={'/'}>
            <PageLogo>
              <StyledLogo color={location.pathname.includes('dead-art') ? '#0A0B0E' : '#fffbf0'}/>
              <LogoText>
                <PageTitle>Paave Art</PageTitle>
                <Slogan><Translate text={'Turning emotions to a picture'}/></Slogan>
              </LogoText>
            </PageLogo>
          </LangLink>
          <Nav lang={lang} location={location}/>
        </NavFlex>
      </Container>
    </Header>
  )

export default Top;
