import { Link } from "gatsby";
import React from "react";
import { LayoutContext } from "./layout";

interface Props {
  children: React.ReactNode;
  to: string;
  lang?: string;
  activeClassName?: string;
}

export const LangLink = ({ children, to, activeClassName }: Props) => {
  return (
    <LayoutContext.Consumer>
      {({ lang }) => {
        return (
          <Link
            to={`${lang !== "cs" ? `/${lang}` : ""}${to}`}
            activeClassName={activeClassName}
          >
            {children}
          </Link>
        );
      }}
    </LayoutContext.Consumer>
  );
};
