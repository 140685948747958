import styled from 'styled-components'
import { rem } from 'polished'

interface Props {
  customTheme?: boolean;
}

export const FooterBox = styled.footer<Props>`
  background-color: ${(props) => props.customTheme ? `rgba(255, 255, 255, 0.1)` : `rgba(255, 255, 255, 0.5)` };
  padding: ${rem(20)} ${rem(10)};
  margin-top: ${rem(20)};
  text-align: center;

  p {
    font-size: 16px;
  }

}
`
