import React from "react";
import { LayoutContext } from "./layout";

const messagesCs = {
  "Paint me the story of my heart": "Namaluj mi příběh mého srdce",
  "Who is Paave": "Kdo je Paave",
  "My story": "Můj příběh",
  "Oil": "Olejomalby",
  "Oil Gallery": "Olejomalby",
  "Dark Oil": "Tajemnomalby",
  "Dark Oil Galllery": "Tajemnomalby",
  "Turning emotions to a picture": "Proměna pocitů v obraz",
  "Paint me a picture": "Namaluj mi obraz",
  "Let your ideas come alive": "Nech své myšlenky oživnout",
  "Watercolor": "Akvarely",
  "Watercolor Gallery": "Akvarely",
  Blog: "Články",
  Contact: "Kontakt",
  "E-shop": "E-shop",
  "Nature oil collection": "Kolekce přírodních olejomaleb",
  "Halloween oil collection": "Kolekce Halloween olejomaleb",
  "Watercolor collection": "Kolekce akvarelových maleb",
  "My name is Pavlina Ostra and the painting is my passion. Rather than visiting institutions I developed my painting skills by experience and watching works from past and current painters.":
    "Jmenuji se Pavlína Ostrá a malování je má vášeň. Své schopnosti malovat rozvíjím jako zapálený samouk, studuji umělecká díla klasických ale i současných malířů.",
  "It all started with fasctionation of mimics and face expressions. I spent many years of studying emotions behind human faces and put them simply on a paper. After longer pause caused by studiying of technical university I discovered my passion to oil paintings and nature landscapes. Love to the nature is my main drive, I like combination of nature and supernatural scenes the most whether it's dark tuned pictures or images what we have inside our minds like unrealizable wishes. There is nothing better than to put your own fantasy on the real picture. The big influence on me has music as well which accompanies me from my young age.":
    "Celé to začalo fascinací obličeji a jejich mimikou. Mnoho let jsem strávila studováním emocí odrážející se v lidských tvářích a ty se pokoušela věrně zachytit na papír. Po pauze způsobené studiem technické vysoké školy se mou vášní stalo malování olejovými barvami a přírodních krajin. Obdiv k přírodě je můj hlavní pohon, nejraději vyjadřuji emoce pomocí spojení přírody a lehce napřirozených výjevů, ať už se jedná o temně laděné obrazy nebo představy, které se nám jako lidem honí v hlavách v podobě neuskutečnitelných přání. Není nic lepšího, než svou fantazii ztvárnit do reálného obrazu.",
  "Though my the most favorite tool are oil paints I like using another techniques or I combine them together. Actually, every painting is coloring book which starts by drawing.":
    "Velký vliv na mě má také hudba, která mě doprovází od útlého věku. Přestože mým nejoblíbenějším nástrojem jsou olejové barvy, ráda využívám i jiné techniky nebo je kombinuji dohromady. Každá malba je totiž vlastně omalovánka, která začíná kresbou.",
  "What people say": "Co říkají lidé",
  "Haven't you find what you've searched for?": "Nenašel jsi, co jsi hledal?",
  "I will help you to choose your personal motive.":
    "Pomůžu ti najít tvůj osobní námět",
  "E-mail": "E-mail",
  "Your name": "Tvé jméno",
  "Choose options": "Vyber možnosti",
  "Choose technique of painting": "Vyber techniku malby",
  "Show me difference between painting mediums":
    "Ukaž mi rozdíl mezi jednotlivými materiály",
  "Watercolor painting": "Akvarelová malba",
  "Oil painting": "Olejomalba",
  "Original painting": "Originální malba",
  "I don't know yet": "Ještě jsem se nerozhodl/a",
  "Reproduction on the canvas": "Reprodukce na plátně",
  "Reproduction on fine art paper": "Reprodukce na výtvarný papír",
  "Describe your idea": "Popiš svou představu",
  "Anything else you would like to know":
    "Cokoliv dalšího, co bys chtěl/a vědět",
  "Make it happen": "Odeslat nezávazně poptávku",
  "Send it to the author": "Odešli autorovi",
  "Sales Gallery": "Prodejní galerie",
  Buy: "Koupit",
  Variants: "Varianty",
  "Private collection": "V soukromé sbírce",
  "Just show me details": "Chci vidět detaily",
  Sold: "Prodáno",
  Reserved: "Rezervováno",
  Size: "Velikost",
  "Year of creation": "Rok vzniku",
  Material: "Materiál",
  Available: "Dostupné",
  "Featured artwork for sale": "Umělecká díla k prodeji",
  "Browse finished dreamscapes": "Prohlédni si hotové vysněné krajiny",
  Exhibitions: "Výstavy",
  "What will I get?": "Co dostanu?",
  "More about me": "Více o mě",
  "I put a certificate of authenticity, print/reproduction I will sign for you.":
    "K originálu ti přiložím certifikát o pravosti díla, jakoukoliv reprodukci ti podepíši.",
  "I'm interested about this artwork": "Mám zájem o toto umělecké dílo",
  "Look at details and the painting process":
    "Prohlédni si detaily a proces malování",
  "The only good is knowledge and the only evil is ignorance":
    "Existuje jen jedno dobro, a to je vědění. Existuje jen jedno zlo, a to je nevědomost",
  "Nature is the source of all true knowledge. She has her own logic, her own laws, she has no effect without cause nor invention without necessity.":
    "Příroda je zdrojem všech skutečných znalostí. Má svou vlastní logiku, své vlastní zákony, nemá žádný účinek bez příčiny ani vynález bez nutnosti.",
  "Music is my God, and it is the only love that has never left me.":
    "Hudba je můj Bůh a je to jediná láska, která mě nikdy neopustila.",
  "404: Not found": "404: Not found",
  "Contact me": "Kontaktuj mě",
  "Painting mediums": "Rozdíl technik",
  "Jsem hypersensitivní. No a co?": "Jsem hypersensitivní. No a co?",
  "Rozdíly technik maleb": "Rozdíly technik maleb",
  "5 let výročí olejomaleb": "5 let výročí olejomaleb",
  "Příběh obrazu “Night Never Ending”": "Příběh obrazu “Night Never Ending”",
  "Kdo že je ten můj malířský vzor": "Kdo že je ten můj malířský vzor",
  "“Did you really start to paint just year ago and something? You’re so talented.”":
    "“Did you really start to paint just year ago and something? You’re so talented.”",
  "I‘m web developer why do I draw and paint?":
    "I‘m web developer why do I draw and paint?",
  "Never it’s too late": "Never it’s too late",
  "The remembrance of my uncle Miloslav":
    "The remembrance of my uncle Miloslav",
  "What did I need to start painting?": "What did I need to start painting?",
  "What does it come into my mind when I’m painting?":
    "What does it come into my mind when I’m painting?",
  "Where do you get inspiration from?": "Where do you get inspiration from?",
  "Jak jsem šla poprvé nakupovat potřeby na malování. Aneb jaké zvolit značky a nástroje":
    "Jak jsem šla poprvé nakupovat potřeby na malování. Aneb jaké zvolit značky a nástroje",
  "Topics": "Témata",
};

export enum Lang {
  cs = "cs",
  en = "en",
}

export type Messages = keyof typeof messagesCs;

interface TranslateSwitchProps {
  cs: React.ReactNode;
  en: React.ReactNode;
}

export const TranslateSwitch = ({ cs, en }: TranslateSwitchProps) => (
  <LayoutContext.Consumer>
    {({ lang }) => (lang === Lang.cs ? cs : en)}
  </LayoutContext.Consumer>
);

interface TranslateProps {
  text: Messages;
}

export const translate = (text: Messages, lang: Lang) =>
  lang === Lang.cs ? messagesCs[text] : text;

export const Translate = ({ text }: TranslateProps) => (
  <LayoutContext.Consumer>
    {({ lang }) => (lang === Lang.cs ? messagesCs[text] : text)}
  </LayoutContext.Consumer>
);

interface TranslateImgProps {
  src: string;
  alt: Messages;
}

export const TranslateImg = ({ src, alt }: TranslateImgProps) => (
  <LayoutContext.Consumer>
    {({ lang }) => (
      <img alt={lang === Lang.cs ? messagesCs[alt] : alt} src={src} />
    )}
  </LayoutContext.Consumer>
);
