import { css } from "styled-components";

const breakpoints = {
  mobile: 768,
  largeMobile: 832,
  tablet: 1024,
  desktop: 1250,
  large: 1920,
};

// iterate through the sizes and create a media template
export const media: any = Object.keys(breakpoints).reduce((accumulator, label) => {
  // use em in breakpoints to work properly cross-browser and support users
  // changing their browsers font-size: https://zellwk.com/blog/media-query-units/
  const emSize: number = breakpoints[label] / 16;
  accumulator[label] = (arg, ...args: any[]) => css `
    @media (min-width: ${emSize}em) {
      ${css(arg, ...args)}
    }
  `;
  return accumulator;
}, {});

export default {
  fontFamily: {
    base: "'Helvetica Neue', Helvetica, Arial, sans-serif",
    PTSans: 'PT Sans, sans-serif, sans-serif',
    Raleway: 'Raleway, sans-serif',
    Tangerine: 'Tangerine, "Times New Roman", cursive, sans-serif',
    Manrope: "'Manrope', sans-serif",
  },
  colors: {
    text: '#202020',
    lightText: '#fffbf0',
    brand: '#a52a2a',
    brandOppositeLight: '#dab182',
    brandOppositeDark: '#6f471c', //dc3300
    link: '#b56714',
    linkDark: '#6f471c',
    menu: '#b56714',
    border: '#F8D2C0',
    borderMenu: '#fbdbc8',
    bg: '#fffbf0',
    inputBg: '#fffbf0',
    gold: '#d58e00',
    cite: '#b56714',
    button: '#d58e00',
    list: '#b56714',
    logo: '#6f471c',
    input: 'black',
    close: '#000',
    ctaColor: '#6f471c',
    ctaHover: '#202020',
    soldColor: '#909296',
    ctaBg: 'rgba(35,18,0,0.8)',
  },
  fontWeights: {
    medium: 300,
    normal: 500,
    bold: 700
  },
  radius: {
    small: 3,
    medium: 5,
    big: 10,
  },
  breakpoints,
  media,
}
